
@font-face {
  font-family: 'NarkissBlock-Bold';
  src:  url('./assets/fonts/NarkissBlock-Bold.ttf');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'NarkissBlock';
  src:  url('./assets/fonts/NarkissBlock-Regular.ttf');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'NarkissBlock-Light';
  src:  url('./assets/fonts/NarkissBlock-Light.ttf');
  font-weight: 400;
  font-style: normal;

}

body{
  font-family: 'NarkissBlock';
}

h1 {
  text-align: center;
  color: #4b4b4b;
  margin-bottom: 50px;
}

.bold{
  font-family: 'NarkissBlock-Bold' !important;
}

.content_page {
  max-width: 800px;
  margin: 0 auto 50px;
}
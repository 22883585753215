.MuiStepIcon-text{
    font-size: 14px !important;
}

.MuiStepIcon-root{
    font-size: 25px !important;
}

svg {
    overflow: visible;
}

.MuiTableContainer-root {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}
button.start{
    display: flex;
    margin: 50px auto 0 0;
    font-size: 18px;
}
th.MuiTableCell-root:first-child {
    width: 30%;
}
/* th.MuiTableCell-root {
    min-width: 80px;
} */

.MuiList-root {
    /* text-align: right; */
    direction: rtl;
}

.buttons_step_4.MuiBox-root {
    max-width: 400px;
    margin: auto;
}


.toStep button {
    font-size: 16px;
}

.toStep span.MuiButton-icon {
    margin: 0;
}

@media (max-width:600px) {

    .opt_table th.MuiTableCell-root:first-child {
        width: 100%;
    }
   
    table.MuiTable-root {
        table-layout: fixed;
        border-collapse: collapse;
    }
    .opt_table td.MuiTableCell-root {
        padding-right: 5px;
        padding-left: 5px;
    }
    .opt_table td.MuiTableCell-root:first-child {
        padding-right: 0;
        padding-left: 4px;
        width: 100% !important;
    }

    .optionalEl {
        gap: 0px !important;
    }
}


@media (max-width:400px) {

    .optionalEl {
       /*flex-wrap: wrap; */
    }
}

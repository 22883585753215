
/* Sticky */
.is-sticky {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    min-height: 50px;
    height: 70px;
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.header {
    direction: rtl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
}

.logo {
    height: 50px;
    margin: 0 auto;
    cursor: pointer;
}

input {
    text-align: right;
}

.MuiStepper-root {
    max-width: 400px;
    margin: 0 auto 50px;
}

th.MuiTableCell-root {
    max-width: 200px;
}

p.comment {
    font-size: 14px;
    color: grey;
}

.step2 .MuiTableContainer-root, .step1 .MuiTableContainer-root  {
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: none;
    max-width: 100%;
}
.step2 .MuiFormControl-root.MuiTextField-root {
    max-width: 150px;
}

.total {
    /* width: 100%; */
    margin: 50px auto;
    text-align: center;
    font-size: 25px;
    color: #d6d6d6;
    background: #4b4b4b;
    padding: 20px;
    border-radius: 5px;
}
.total .bold{
    color: #fff;
}
.MuiStack-root {
    width: 250px;
}

.step3 .MuiTableContainer-root {
    max-width: 400px;
    margin: 100px auto 50px;
}
.step3 label.MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}
.step3 .MuiTableContainer-root table.MuiTable-root {
    min-width: 400px;
}

.step3 .MuiFormControl-root {
    flex: 0 1 90px;
}
.step3 .MuiFormControl-root input {
    text-align: center;
}

.step3 span.MuiSwitch-root {
    margin-left: 21px;
}

.step.step4 {
    max-width: 400px;
    margin: auto;
}

.step4 .results {
    margin: 40px auto;
    font-size: 18px;
    /*text-align: center;*/
}
.step4 span.accept {
    color: #007a82;
    font-family: 'NarkissBlock-Bold';
    display: flex;
    align-items: center;
    gap: 5px;
}

.step4 .footer {
    margin: 50px 0;
    border-top: 1px solid #e5e5e5;
    padding-top: 30px;
    color: #4b4b4b;
}

a {
    color: #007a82;
    text-decoration: none;
}

.optionalEl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

@media (max-width:600px) {
    .step2 th.MuiTableCell-root:not(:first-child) {
        width: calc(70% / 3);
        text-align: center;
    }
    .step2 td.MuiTableCell-root:not(:first-child) {
        text-align: center;
    }
    .step2 td.MuiTableCell-root input{
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
    }
    .MuiFormControl-root.MuiTextField-root {
        width: 50px;
    }
    .step3 .MuiFormControl-root {
        flex: 0 1 70px !important;
    }
    .step3 span.MuiSwitch-root {
        margin-left: 10px;
    }
    table.MuiTable-root {
        min-width: 100% !important;
    }
    
}
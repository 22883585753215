

.white-logo {
    height: 50px;
}

.desc {
    color: white;
    flex: 1;
    text-align: center;
}

.side-header-items {
    /* padding: 20px; */
    font-size: 20px;
    font-weight: bold;
    color: white;
    padding: 26px 0 0 5px;
}